import { render, staticRenderFns } from "./TrillAccountModule.vue?vue&type=template&id=e97a8ae6&scoped=true"
import script from "./TrillAccountModule.vue?vue&type=script&lang=js"
export * from "./TrillAccountModule.vue?vue&type=script&lang=js"
import style0 from "./TrillAccountModule.vue?vue&type=style&index=0&id=e97a8ae6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e97a8ae6",
  null
  
)

export default component.exports