<template>
    <div class="details">
      <div class="details-top">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="interactive-breadcrumb">
          <el-breadcrumb-item class="first">账号管理</el-breadcrumb-item>
          <el-breadcrumb-item class="second">抖音账号数据详情</el-breadcrumb-item>
        </el-breadcrumb>
        <el-button class="blue-btn" @click="toBack">返回</el-button>
      </div>
      <div class="details-bottom" style="display: flex;justify-content: start">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="数据概况" name="first" lazy><DataOverviewModule ref="DataOverviewModule" /></el-tab-pane>
          <el-tab-pane label="粉丝数据分析" name="second"  lazy><FanDataModule ref="FanDataModule" /></el-tab-pane>
          <el-tab-pane label="播主视频" name="third" lazy><BroadcasterVideoModule ref="BroadcasterVideoModule" /></el-tab-pane>
          <!--<el-tab-pane label="电商数据分析" name="fourth" lazy><EcommerceDataModule /></el-tab-pane>-->
        </el-tabs>
        <div class="update-content">
          <span @click="updateBtn" class="update-title"><i class="iconfont">&#xe64d;</i>更新数据</span>{{update_time}}
        </div>
        <TrillAccountModule @selectAccountUpdate="selectAccountUpdate" ref="TrillAccountModule" />
<!--        <MineModule ref="mineModule"></MineModule>-->
      </div>

    </div>
</template>

<script>
    import DataOverviewModule from '../../../components/creativecentermodule/DataOverviewModule.vue'
    import MineModule from '../../../components/creativecentermodule/MineModule.vue'
    import TrillAccountModule from '../../../components/creativecentermodule/TrillAccountModule.vue'
    import FanDataModule from '../../../components/creativecentermodule/FanDataModule.vue'
    import BroadcasterVideoModule from '../../../components/creativecentermodule/BroadcasterVideoModule.vue'
    // import EcommerceDataModule from '../../../components/creativecentermodule/EcommerceDataModule.vue'
    export default {
        name: "DataDetails",
        components:{
            DataOverviewModule,
            MineModule,
            FanDataModule,
            BroadcasterVideoModule,
            TrillAccountModule,
            // EcommerceDataModule,
        },
        data() {
            return {
                activeName: 'first',
                update_time:'',
                accountID:'',//账户id
            }
        },
        mounted(){
            let name = localStorage.getItem('currentTab')
            // 判断是否存在currentTab，即tab页之前是否被点击切换到别的页面
            if(name){
                this.activeName = name
            }
            this.accountID=this.$route.query.id//从路由获取id
            let platform = this.$route.query.platform
            let type = this.$route.query.type
            setTimeout(()=>{
                if(this.accountID&&platform&&type){
                    this.$refs.TrillAccountModule.getAccountMine(type,platform,this.accountID)
                }
            },100)
        },
        methods: {
            handleClick(tab) {
                // 点击tab后触发事件，修改显示页面，将状态保存在localStorage里面
                localStorage.setItem('currentTab', tab.name);
                if(tab.name === 'first'){
                  this.$nextTick(()=>{
                    this.$refs.DataOverviewModule.getDate();
                  })
                }
                if(tab.name === 'second'){
                  this.$nextTick(()=>{
                    this.$refs.FanDataModule.getData();
                  })
                }
                if(tab.name === 'third'){
                  this.$nextTick(()=>{
                    this.$refs.BroadcasterVideoModule.getMyVideoList();
                  })
                }
            },
            // 更新抖音账户数据
            updateBtn() {
                if(this.activeName !=='third'){
                    this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_refresh, {account_id:this.accountID}, res => {
                        if (res.code === 200) {
                            this.update_time = res.data.update_time;
                            this.$message({
                                type: "success",
                                message: res.message,
                                duration: 1000
                            });
                        } else {
                            this.$message({
                                type: "error",
                                message: res.message,
                                duration: 1000
                            });
                        }
                    }, (err) => {
                    })
                } else {
                    this.$message.warning('请前往创作中心->视频管理->选择账号->点击更新列表按钮')
                }
            },
            selectAccountUpdate(id){
              if(this.activeName === 'first'){
                this.$refs.DataOverviewModule.getDate();
              }
              if(this.activeName === 'second'){
                this.$refs.FanDataModule.getData();
              }
              if(this.activeName === 'third'){
                this.$refs.BroadcasterVideoModule.getMyVideoList();
              }
            },
            toBack(){
              this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    ::v-deep .el-tabs__header{
      margin: 0;
    }
    ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav{
    border: none;
}
::v-deep .is-active{
            background-color: white;
            // color: red;
            
    }

      .details{
        display: flex;
        //justify-content: start;
        flex-direction: column;
        height: 100%;
        .details-top{
          display: flex;
          position: relative;
          .blue-btn{
            padding: 8px 25px;
            font-size: 14px;
            position: absolute;
            right: 0;
            bottom: 10px;
          }
        }
        .details-bottom{
        // background-color: white;

          position: relative;
        }
        ::v-deep.el-tabs{
            box-shadow: none;
            border: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            background: transparent;
            flex: 1;
            width: 1%;
            min-height: 838px;
            position: relative;
        }
        ::v-deep.el-tabs__header{
            border-bottom: none;
            margin: 0;
        }
        ::v-deep.el-tabs__content{
            flex: 1;
            background: #fff;
            padding-top: 20px;
        }
        .el-tab-pane{
            height: 100%;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item.is-active{
            background: #fff;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__item{
            border-left: none;
        }
        ::v-deep.el-tabs__header .is-active {
            background: #fff;
            font-size: 16px;
            font-weight: 400;
            color: #0824ce;
            border-radius: 6px 6px 0 0;
        }
        ::v-deep.el-tabs__item{
            height: 52px;
            line-height: 52px;
        }
        ::v-deep.el-tabs--card>.el-tabs__header .el-tabs__nav{
            border: none;
        }
        ::v-deep.el-tabs__item:hover {
            color: #0824ce;
            cursor: pointer;
        }
        .update-content{
            height: 20px;
            position: absolute;
            top: 14px;
            right: 378px;
            .update-title{
                margin: 0 10px;
                font-size: 14px;
                cursor: pointer;
                i{
                    color: #0824ce;
                    margin-right: 10px;
                }
                &:hover{
                    color: #1D33E1;
                }
            }
        }
    }
</style>